@import "src/styles/abstracts/abstracts";
@import "src/styles/modules/typography";

.modal {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 600px;
  height: 100vh;
  transform: translateX(100%);
  background-color: $bg-color;
  border-radius: $border-r 0px 0px $border-r;
  padding: 40px 0;
  animation: fade 0.3s;
  z-index: 1;
  transition: 0.4s;
  display: flex;
  flex-direction: column;

  .open & {
    transform: translateX(0);
    box-shadow: -4px 4px 21px 0px rgba(0, 0, 0, 0.18);
  }
}

.overlay {
  .open & {
    position: fixed;
    inset: 0;
  }
}

.title {
  @extend h2;
  margin-bottom: 24px;
  padding: 0 40px;
  color: $dark-blue;
}

.content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 20px 40px 24px;
}

.bottom {
  margin-top: 24px;
  padding: 0 40px;
}
