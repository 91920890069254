.container {
  padding-left: 20px;
  padding-right: 40px;
}

.btn {
  @extend %btnreset;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
}

.marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $blue;
}

.whiteSpaceNormal {
  white-space: normal !important;
}

.alert {
  color: $alert;
}
